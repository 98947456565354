/***************************************************
  Imports
***************************************************/

import React from "react"

import List from "../../components/List"
import PageTypeE from "../../templates/PageTypeE"

import TitleImg from "../../images/D365-manufacturing.png"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Manufacturing"
      description="Manufacturing effectively manages production, including production orders, bills of material, supply planning, and capacity requirements planning."
      linkSubPageGroupID="D365Group"
      titleImg={TitleImg}
      infoList={[
        {
          title: "Boost operational efficiency",
          text: `With Microsoft Dynamics Business Central for Manufacturing, any size company can boost operational efficiency and effectively manage production, including production orders, bills of material, supply planning, and capacity requirements planning. Flexible processes and integrated information equip you to make accurate promises to customers, respond quickly to last-minute requests and changes, and take advantage of new business opportunities to help your business stay ahead of the competition.`,
        },
        {
          title: "Production Management",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Improve coordination of made-to-order requests`,
                },
                {
                  text: `Simplify make-or-buy decisions`,
                },
                {
                  text: `Modify components and operations as needed, even on released production orders`,
                },
                {
                  text: `Plan product family orders that share the same routing to create efficient build schedules`,
                },
              ]}
            />
          ),
        },
        {
          title: "Bill of Materials (BOMs)",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Integrate different types of BOMs and customized definitions into manufacturing operations`,
                },
                {
                  text: `Support time-to-market and time-to-volume objectives`,
                },
                {
                  text: `Create, track, and process multiple versions of BOMs and routings`,
                },
              ]}
            />
          ),
        },
        {
          title: "Graphical scheduling",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Access an overview of production schedules displayed in Gantt charts`,
                },
                {
                  text: `Use drag-and-drop functionality to reschedule operations`,
                },
              ]}
            />
          ),
        },
        {
          title: "Order promising",
          text: `Promise orders more accurately with available-to-promise(ATP) and capable-to-promise(CTP) insight`,
        },
        {
          title: "Exceptions handling",
          text: `Quickly make exceptions and last-minute changes to meet customer needs`,
        },
        {
          title: "Supply planning",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Plan from the sales order, production order, or purchase requisition, or use traditional master production schedule(MPS) or material requirements planning(MRP) methods`,
                },
                {
                  text: `Facilitate materials flow through the supply chain with multi-location planning capabilities`,
                },
                {
                  text: `Update and change all materials, costs, and operations simultaneously`,
                },
              ]}
            />
          ),
        },
        {
          title: "Demand forecasting",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Analyze sales patterns from multiple perspectives`,
                },
                {
                  text: `Compare forecasted demand with actual sales`,
                },
                {
                  text: `Consolidate and distribute demand plans with multiple stakeholders for collaborative planning`,
                },
              ]}
            />
          ),
        },
        {
          title: "Capacity requirements planning",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Implement realistic plans according to all incoming resource capacity demands`,
                },
                {
                  text: `Redefine order modifiers and reorder policies as needed`,
                },
                {
                  text: `Accommodate changing shop floor workloads Simplify planning with calendars and machine center allocation tools`,
                },
              ]}
            />
          ),
        },
        {
          title: "Warehouse and inventory management",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Integrate manufacturing and warehousing functionality`,
                },
                {
                  text: `Optimize warehouse layout and space utilization`,
                },
                {
                  text: `Maintain up-to-date inventory information`,
                },
              ]}
            />
          ),
        },
        {
          title: "Manufacturing costing",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Understand item costs throughout the production process, including inventory, WIP, and cost of goods sold (COGS)`,
                },
                {
                  text: `Manage sales and purchase prices with customers and vendors`,
                },
              ]}
            />
          ),
        },
        {
          title: "Automated data collection system (ADCS)",
          text: (
            <List
              className="dark"
              items={[
                {
                  text: `Collect and use accurate inventory data in real time`,
                },
                {
                  text: `Increase the visibility of accurate inventory data throughout your business`,
                },
                {
                  text: `Improve warehouse efficiency`,
                },
              ]}
            />
          ),
        },
      ]}
    />
  )
}
